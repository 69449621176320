<template>
  <div class="base-machines-details">
    <vs-loader v-if="isLoading" />
    <b-container fluid v-else>
      <b-row>
        <b-col :cols="reportable ? '4' : '7'">
          <machine-details-card />
        </b-col>

        <b-col cols="2">
          <base-cars-location-card
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="3">
          <base-cars-service-card :resource="machine" type="machines" />
        </b-col>

        <b-col cols="3">
          <machine-equipment-card @attached:update="fetchMachineData" />
        </b-col>

        <template v-if="reportable">
          <b-col cols="6" v-can:show="'base.resource_report'">
            <machines-moto-hours-card v-if="machine.name" />
          </b-col>

          <b-col cols="6" v-can:show="'base.resource_report'">
            <machines-refueling-card v-if="machine.name" />
          </b-col>
        </template>

        <b-col cols="6">
          <base-cars-schedule-card
            :resource="machine"
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="6">
          <attachments-card v-if="files" :files="files" />
        </b-col>
      </b-row>
    </b-container>

    <base-service-create-modal />

    <machines-mth-report-modal
      :resource-editable="false"
      @ok="onReportCreate"
    />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import {
  computed, provide, ref, watch,
} from 'vue';
import MachinesMotoHoursCard from '@/components/views/base/machines/widgets/MachinesMotoHoursCard.vue';
import MachineDetailsCard from '@/components/views/base/machines/widgets/MachineDetailsCard.vue';
import MachinesRefuelingCard from '@/components/views/base/machines/widgets/MachineRefuelingCard.vue';
import MachineEquipmentCard from '@/components/views/base/machines/widgets/MachineEquipmentCard.vue';
import MachinesMthReportModal from '@/components/views/base/machines/modals/MachinesMthReportModal.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import useMachineMthCard from '@/hooks/base/machine/useMachineMthCard';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import BaseCarsServiceCard from '@/components/views/base/cars/widgets/BaseCarsServiceCard.vue';
import BaseServiceCreateModal from '@/components/views/base/service/modals/BaseServiceCreateModal.vue';
import { useRouter } from '@/@core/utils/utils';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import BaseCarsLocationCard from '@/components/views/base/cars/widgets/BaseCarsLocationCard.vue';
import BaseCarsScheduleCard from '@/components/views/base/cars/widgets/BaseCarsScheduleCard.vue';
import AttachmentsCard from '@/components/views/base/resource/widgets/AttachmentsCard.vue';

export default {
  name: 'MachinesDetails',
  setup() {
    const { route } = useRouter();
    const machineId = computed(() => route.value.params.id);
    const machine = ref({ id: machineId.value });
    const isLoading = ref(false);

    const fetchMachineData = () => {
      isLoading.value = true;
      useBaseApi()
        .fetchMachine(machineId.value)
        .then(({ data }) => {
          machine.value = {
            ...data.data,
          };
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    fetchMachineData();

    watch(machineId, fetchMachineData);

    provide('machine', machine);
    provide('fetchMachineData', fetchMachineData);

    const onReportCreate = () => {
      useMachineMthCard().updateChart(machineId.value, true);
    };

    const reportable = computed(() => machine.value?.category?.be_reported);

    const currentLocation = ref(null);
    const futureLocations = ref([]);
    useInvestmentsApi()
      .getAllLocations(machine.value.id)
      .then(({ data }) => {
        currentLocation.value = data.data.current_location ?? null;
        futureLocations.value = data.data.future_locations.filter(
          (location) => location.id !== currentLocation.value?.id,
        ) ?? null;
      });

    const files = computed(() => machine.value.files);

    return {
      machine,
      onReportCreate,
      fetchMachineData,
      isLoading,
      reportable,
      currentLocation,
      futureLocations,
      files,
    };
  },
  components: {
    MachinesRefuelingCard,
    MachineEquipmentCard,
    MachinesMotoHoursCard,
    MachineDetailsCard,

    AttachmentsCard,
    BContainer,
    BRow,
    BCol,
    MachinesMthReportModal,
    VsLoader,
    BaseCarsServiceCard,
    BaseServiceCreateModal,
    BaseCarsLocationCard,
    BaseCarsScheduleCard,
  },
};
</script>

<style lang="sass" scoped>
.col-5
  align-items: stretch
</style>
